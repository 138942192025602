import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

import { CompilationStatus, QueryAnalysisSucceeded, QueryCompilationSucceeded } from 'common/types/compiler';
import {
  AnalyzedSelectedExpression,
  Expr,
  isColumnEqualIgnoringPosition,
  isColumnRef,
  nullLiteral,
  Scope,
  soqlRendering,
} from 'common/types/soql';
import { View } from 'common/types/view';
import { Tab } from 'common/explore_grid/types';
import { extractSelectionForColumnNames } from 'common/soql/binary-tree';
import {
  getTableAliases,
  lastInChain,
  querySuccess,
  viewContextFromQuery,
  findVQEColumnCaseInsensitive,
  getSourceColumnFromSelection
} from '../lib/selectors';
import { isCalculatedColumn, isSelectedColumn, SelectionItem, selectionWithProvenanceFromQueryAnalysis, toExpr, zipSelection } from '../lib/soql-helpers';
import { Query, QueryResult, QuerySuccess, VQEColumn } from '../redux/store';
import _ from 'lodash';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { none, Option, option, some } from 'ts-option';
import ColumnHeader, { ColumnFormatLookup } from './ColumnHeader';
import { Row, SoQLTypeLookup } from './Row';
import ToolTip from './ToolTip';
import * as VisualContainer from './visualContainer';
import { VisualContainerProps } from './visualContainer';
import { AppState, ContextualEventHandlers } from '../redux/store';
import { RemoteStatusInfo } from '../redux/statuses';
import GridRedockBanner from './GridRedockBanner';
import { fetchTranslation } from 'common/locale';
import NoRowsAgGridOverlay from 'common/components/NoRowsAgGridOverlay';
import { FeatureFlags } from 'common/feature_flags';
import EcAgTable from './EcAgTable';
import { Either } from 'common/either';
import { usingNewAnalysisEndpoint, whichAnalyzer } from '../lib/feature-flag-helpers';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.grid_table');

interface TableProps {
  result: QuerySuccess;
  runAST: VisualContainer.RunAST;
  compileAST: VisualContainer.CompileAST;
  applyChanges: VisualContainer.ApplyChanges;
  discardChanges: VisualContainer.DiscardChanges;
  isInProgress: boolean;
  query: Query;
  scope: Scope;
  updateTab?: (tab: Tab) => void;
  view: View;
  modalTargetWindow: AppState['modalTargetWindow'];
  contextualEventHandlers: Partial<ContextualEventHandlers>;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  columns: VQEColumn[];
  fourfour: string;
}

interface TableState {
  showSuccess: boolean;
  unappliedChangesModalOpen: boolean;
  onDiscardCallback: () => void;
}

const GridLoading = () => (
  <div className="grid-loading">
    {/* 07/2024: The React Wrapper equivalent does not exist yet */}
    <forge-busy-indicator
      className="compiling"
      manageFocus={false}
      fixed={false}
      message={t('running_query')}
    ></forge-busy-indicator>
  </div>
);

const buildTypeLookup = (selection: AnalyzedSelectedExpression[]): SoQLTypeLookup => {
  const table = selection.reduce((acc, s) => {
    return { ...acc, [s.name]: s.expr.soql_type };
  }, {});
  return (fn: string) => table[fn];
};

export const getFilteredColumns = (expr: Expr): Expr[] => {
  if (expr && expr.type === 'column_ref') {
    return [expr];
  } else if (!_.isEmpty(_.get(expr, 'args'))) {
    return _.get(expr, 'args').reduce((acc: Expr[], arg: Expr) => acc.concat(getFilteredColumns(arg)), []);
  } else {
    return [];
  }
};

export const getFilteredColumnNames = (expr: Expr): string[] => {
  if (expr && expr.type === 'column_ref') {
    return [expr.value];
  } else if (!_.isEmpty(_.get(expr, 'args'))) {
    return _.get(expr, 'args').reduce(
      (acc: string[], arg: Expr) => acc.concat(getFilteredColumnNames(arg)),
      []
    );
  } else {
    return [];
  }
};

const doesArrayIncludeAnalyzedSelectedExpression = (s: AnalyzedSelectedExpression, arr: Expr[]): boolean => {
  const unTypedExprS = toExpr(s.expr);
  if (isColumnRef(unTypedExprS)) {
    return arr.some((expr) =>
      isColumnRef(expr) ? isColumnEqualIgnoringPosition(expr, unTypedExprS) : false
    );
  } else {
    return arr.some((expr) => getFilteredColumnNames(expr).includes(s.name));
  }
};
const doesArrayIncludeAnalyzedSelectedExpressionNA = (s: SelectionItem, arr: Expr[]): boolean => {
  if (isColumnRef(s.expr)) {
    return arr.some((expr) => isColumnRef(expr) && isColumnEqualIgnoringPosition(expr, expr)
    );
  } else {
    return arr.some((expr) => getFilteredColumnNames(expr).includes(s.schemaEntry.name));
  }
};

// TODO: Don't conflate column formatting and UI behavior! lumping the highlighted
// selected/filtered column backgrounds in with user level formatting is super confusing
const buildFormatLookup = (
  compilation: Either<QueryCompilationSucceeded, QueryAnalysisSucceeded>,
  columns: VQEColumn[],
  filteredColumns: Expr[] = [],
  selectedColumns: Expr[] = [],
  showSuccess = false,
  showSelected = false
): ColumnFormatLookup => {
  const table = (() => {
    if (usingNewAnalysisEndpoint()) {
      const selection = selectionWithProvenanceFromQueryAnalysis(compilation.right);
      return selection.reduce((acc, s) => {
        if (isSelectedColumn(s) || isCalculatedColumn(s)) {
          const name = s.schemaEntry.name;
          const sourceCol = s.provenance.map(p => p.column);
          const width = sourceCol.map(c => c.width);

          const format = option(columns.find(col => col.fieldName === s.schemaEntry.name.toLowerCase()))
            .map((vc) => {
              if (vc.width) return { ...vc.format, width: vc.width };
              else if (width.isDefined)
                return { ...vc.format, width: width.get };
              return vc.format;
            })
            .getOrElseValue({});
          const filtered = doesArrayIncludeAnalyzedSelectedExpressionNA(s, filteredColumns);
          const selected = doesArrayIncludeAnalyzedSelectedExpressionNA(s, selectedColumns);

          let background = undefined;
          if (filtered && showSuccess) {
            background = 'success';
          } else if (selected && showSelected) {
            background = 'selected';
          }
          return {
            ...acc,
            [name]: { ...format, background }
          };
        }
        return acc;
      }, {} as Record<string, any>);
    } else {
      const selection = lastInChain(compilation.left.analyzed).selection;
      return selection.reduce((acc, s) => {
        const sourceCol = getSourceColumnFromSelection(s, compilation.left);

        const format = findVQEColumnCaseInsensitive(columns, s)
          .map((vc) => {
            if (vc.width) return { ...vc.format, width: vc.width };
            else if (sourceCol.nonEmpty && sourceCol.get.width)
              return { ...vc.format, width: sourceCol.get.width };
            return vc.format;
          })
          .getOrElseValue({});
        const filtered = doesArrayIncludeAnalyzedSelectedExpression(s, filteredColumns);
        const selected = doesArrayIncludeAnalyzedSelectedExpression(s, selectedColumns);

        let background = undefined;
        if (filtered && showSuccess) {
          background = 'success';
        } else if (selected && showSelected) {
          background = 'selected';
        }
        return {
          ...acc,
          [s.name]: { ...format, background }
        };
      }, {} as Record<string, any>);
    }
  })();

  return (fieldName: string) => {
    return table[fieldName] || {};
  };
};

const getShowSuccess = (query: Query): boolean =>
  _.get(query, 'queryResult').match({
    none: () => false,
    some: (queryResult: QueryResult) => queryResult.type === 'query_success'
  });

const showSuccessDuration = 3000;

class Table extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);
    this.state = {
      showSuccess: getShowSuccess(props.query),
      unappliedChangesModalOpen: false,
      onDiscardCallback: _.noop
    };
    if (this.state.showSuccess) {
      setTimeout(this.hideSuccess, showSuccessDuration);
    }
  }

  toolTipRef = React.createRef<ToolTip>();

  shouldComponentUpdate(nextProps: TableProps, nextState: TableState) {
    // This is a function now because I didn't want to do complicated boolean logic, but also wanted to
    // defer execution until the end of the giant boolean down below as before.
    const compilationResultChanged = () => {
      if (usingNewAnalysisEndpoint()) {
        return nextProps.query.analysisResult
          .map(
            (next) =>
              this.props.query.analysisResult
                .map((current) => {
                  const nextQuery = next.type === CompilationStatus.Succeeded ? next.text : '';
                  const currentQuery = current.type === CompilationStatus.Succeeded ? current.text : '';
                  return nextQuery !== currentQuery;
                })
                .getOrElseValue(true) || nextProps.columns !== this.props.columns
          )
          .getOrElseValue(true);
      } else {
        return nextProps.query.compilationResult
          .map(
            (next) =>
              this.props.query.compilationResult
                .map((current) => {
                  const nextQuery = next.type === CompilationStatus.Succeeded ? next.rendering : '';
                  const currentQuery = current.type === CompilationStatus.Succeeded ? current.rendering : '';
                  return nextQuery !== currentQuery;
                })
                .getOrElseValue(true) || nextProps.columns !== this.props.columns
          )
          .getOrElseValue(true);
      }
    };

    // if the query hasn't changed, don't re-render the whole table
    // if this check isn't here or gets broken in some way, the
    // whole UI becomes completely unusable
    return (
      // don't re-render on text changing
      querySuccess(nextProps.query.queryResult)
        .map((next) =>
          querySuccess(this.props.query.queryResult)
            .map((current) => next.relevanceId !== current.relevanceId)
            .getOrElseValue(true)
        )
        .getOrElseValue(true) ||
      nextProps.isInProgress !== this.props.isInProgress ||
      getShowSuccess(this.props.query) !== getShowSuccess(nextProps.query) ||
      nextState.showSuccess !== this.state.showSuccess ||
      nextProps.contextualEventHandlers !== this.props.contextualEventHandlers ||
      compilationResultChanged()
    );
  }

  componentDidUpdate(prevProps: TableProps) {
    const newShowSuccess = getShowSuccess(this.props.query);
    const oldShowSuccess = getShowSuccess(prevProps.query);

    if (newShowSuccess && (!oldShowSuccess || prevProps.isInProgress)) {
      this.setState({ showSuccess: newShowSuccess });
      setTimeout(this.hideSuccess, showSuccessDuration);
    }
  }

  hideSuccess = () => {
    this.setState({ showSuccess: false });
  };

  openTab = (tab: Tab) => {
    const { updateTab } = this.props;
    if (updateTab) updateTab(tab);
  };

  handleOneMouseOver = (event: MouseEvent<HTMLTableDataCellElement, globalThis.MouseEvent>) => {
    const element = event.currentTarget;
    if (element !== null && this.toolTipRef.current !== null) {
      const rect = element.getBoundingClientRect();
      let data = '';
      if (element.firstChild instanceof HTMLElement) {
        data = element.firstChild.textContent || element.firstChild.innerHTML;
      }
      this.toolTipRef.current.show(rect, data);
    }
  };

  handleOneMouseOut = () => {
    if (this.toolTipRef.current) {
      this.toolTipRef.current.hide();
    }
  };

  render() {
    const analyzedSelection = extractSelectionForColumnNames(this.props.result.compiled.analyzed);
    const unanalyzed = lastInChain(this.props.result.compiled.unanalyzed);
    const { selectedColumns, newQuery } = this.props.query.compilationResult
      .map((compiled) => {
        if (compiled.type !== CompilationStatus.Succeeded)
          return { selectedColumns: [], newQuery: soqlRendering.wrap('') };

        const compiledUnanalyzed = lastInChain(compiled.unanalyzed);
        return {
          selectedColumns: getFilteredColumns(compiledUnanalyzed.where || nullLiteral).concat(
            getFilteredColumns(compiledUnanalyzed.having || nullLiteral)
          ),
          newQuery: compiled.rendering
        };
      })
      .getOrElseValue({ selectedColumns: [], newQuery: soqlRendering.wrap('') });
    const filteredColumns = getFilteredColumns(unanalyzed.where || nullLiteral).concat(
      getFilteredColumns(unanalyzed.having || nullLiteral)
    );
    const filteredColumnNames = getFilteredColumnNames(unanalyzed.where || nullLiteral).concat(
      getFilteredColumnNames(unanalyzed.having || nullLiteral)
    );
    const columnNames = analyzedSelection.map((s) => s.name);
    const typeLookup = buildTypeLookup(analyzedSelection);
    const showSelected = newQuery !== this.props.result.compiled.rendering;
    const formatLookup = buildFormatLookup(
      whichAnalyzer(r => r.compiled, r => r.analyzed.get)(this.props.result),
      this.props.columns,
      filteredColumns,
      selectedColumns,
      this.state.showSuccess,
      showSelected
    );
    const viewContext = viewContextFromQuery(this.props.query);
    const tableAliases = getTableAliases(this.props.query);

    const unanalyzedSelection = viewContext.map((vc) => {
      return zipSelection(vc, tableAliases, unanalyzed.selection, analyzedSelection);
    });

    const typedSelect = this.props.result.analyzed.map(analysis => lastInChain(analysis.ast));
    const selectedItems = this.props.result.analyzed.map(selectionWithProvenanceFromQueryAnalysis);
    if (FeatureFlags.value('enable_explore_grid_table_upgrade')) {
      return (
        <EcAgTable
          result={this.props.result}
          runAST={this.props.runAST}
          query={this.props.query}
          columns={this.props.columns}
          fourfour={this.props.fourfour}
        />);
    } else {
      return (
        <div data-testid='ec-grid-table'>
          <ToolTip data={''} ref={this.toolTipRef} />
          <table>
            <thead>
              <tr>
                {analyzedSelection.map((selection, i) => (
                  <ColumnHeader
                    position={i}
                    key={i}
                    tableAliases={tableAliases}
                    viewContext={viewContext}
                    vqeColumn={findVQEColumnCaseInsensitive(this.props.columns, selection)}
                    selection={selection}
                    scope={this.props.scope}
                    unanalyzedSelectedExpr={unanalyzedSelection.flatMap((un) => option(un.exprs[i]))}
                    unanalyzed={unanalyzed}
                    analyzedSelection={analyzedSelection}
                    runAST={this.props.runAST}
                    compileAST={this.props.compileAST}
                    formatLookup={formatLookup}
                    openTab={this.openTab}
                    filteredColumns={filteredColumnNames}
                    toEditColumnMetadata={this.props.contextualEventHandlers.editColumnMetadata}
                    toFormatColumn={this.props.contextualEventHandlers.formatColumn}
                    toHandleColumnWidthChange={this.props.contextualEventHandlers.handleColumnWidthChange}
                    remoteStatusInfo={this.props.remoteStatusInfo}
                    modalTargetWindow={this.props.modalTargetWindow}
                    applyChanges={this.props.applyChanges}
                    discardChanges={this.props.discardChanges}
                    typedSelect={typedSelect}
                    selectionItem={selectedItems.map(items => items[i])}
                    numColumns={selectedItems.map(items => items.length)}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.result.rows.map((data, i) => {
                return (
                  <Row
                    uid={this.props.view.id}
                    key={i}
                    names={columnNames}
                    data={data}
                    typeLookup={typeLookup}
                    formatLookup={formatLookup}
                    onMouseOver={this.handleOneMouseOver}
                    onMouseOut={this.handleOneMouseOut}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }

  }
}

interface StateProps {
  contextualEventHandlers: Partial<ContextualEventHandlers>;
  modalTargetWindow: AppState['modalTargetWindow'];
  remoteStatusInfo: Option<RemoteStatusInfo>;
  columns: VQEColumn[];
}

// this wraps the above table component
function GridTable(props: VisualContainerProps & StateProps) {
  const {
    query,
    scope,
    runAST,
    updateTab,
    compileAST,
    applyChanges,
    discardChanges,
    view,
    contextualEventHandlers,
    modalTargetWindow,
    remoteStatusInfo,
    columns,
    fourfour
  } = props;

  // you may ask yourself: why do we handle the loading state but also render the table?
  // for changes in the query, we have a queryResult, it just
  // may not correspond to the current compilationresult - ie: the query is running.
  // this handles the "there is a query running" state, where we want to render the stale
  // table with a translucent overlay which says it's loading.
  // this is
  //   * prettier
  //   * doesn't blow away the table component on each query, just re-renders
  //     which is critical for keeping UI state like horizontal scroll position
  //     between page clicks
  const loading = query.isQueryInProgress || query.queryResult.isEmpty ? <GridLoading /> : null;

  const refresh = () => window.location.reload();

  const error = () => {
    return (
      <div className="grid-table-error">
        <img
          className="grid-table-error-illustration grid-table-error-center"
          src="https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg"
          alt=""
        />
        <div className="grid-table-error-message">
          <p className="forge-typography--body1">
            {t('we_are_sorry')} <br /> {t('refresh_the_page')}
          </p>
          <ForgeButton type="raised">
            <button type="button" onClick={refresh}>
              <ForgeIcon name="refresh"></ForgeIcon>
              <span>{t('refresh_button')}</span>
            </button>
          </ForgeButton>
        </div>
      </div>
    );
  };

  const inner = query.queryResult.match({
    none: () => {
      return null;
    },
    some: (result: QueryResult) => {
      if (result.type === 'query_success') {
        return (
          <>
            <Table
              query={query}
              scope={scope}
              result={result}
              runAST={runAST}
              compileAST={compileAST}
              applyChanges={applyChanges}
              discardChanges={discardChanges}
              isInProgress={query.isQueryInProgress}
              updateTab={updateTab}
              contextualEventHandlers={contextualEventHandlers}
              modalTargetWindow={modalTargetWindow}
              remoteStatusInfo={remoteStatusInfo}
              view={view}
              columns={columns}
              fourfour={fourfour}
            />
            {!result.rows.length && (
              <NoRowsAgGridOverlay
                outerDivClassName="grid-table-no-results"
                imageClassName="grid-table-not-found-illustration"
                textSectionClassName="grid-table-no-results-message"
              />
            )}
          </>
        );
      }
      return error();
    }
  });

  return (
    <div className="grid-table-container">
      {props.undocked && <GridRedockBanner />}
      {loading}
      <div className="grid-table table table-condensed">{inner}</div>
    </div>
  );
}

const mapStateToProps = (state: AppState, props: VisualContainer.ExternalProps): VisualContainer.VisualContainerStateProps & StateProps => {
  return {
    ...VisualContainer.mapStateToProps(state, props),
    modalTargetWindow: state.modalTargetWindow,
    remoteStatusInfo: state.remoteStatusInfo,
    contextualEventHandlers: state.contextualEventHandlers,
    columns: state.columns,
    fourfour: state.fourfour
  };
};

export default connect(
  mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(GridTable);
