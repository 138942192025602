import _ from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import {
  ColumnRef,
  isColumnRef,
  TypedSoQLColumnRef,
  isTypedColumnRef,
  NoPosition,
  TypedSelect,
  UnAnalyzedAst,
  NamedExpr,
  UnAnalyzedSelectedExpression,
  isExpressionEqualIgnoringPosition
} from 'common/types/soql';
import {
  analysisSuccess,
  getRightmostLeafFromAnalysis,
  compilationSuccess,
  getLastAnalyzedAst,
  getLastUnAnalyzedAst,
  lastInChain,
  getSourceColumnFromSelection,
  getSourceColumnFromSelectionNA,
  getEditableSelectedExpressions
} from '../../lib/selectors';
import {
  getAllExcludedCalculatedColumns,
  getAllExcludedCalculatedColumnsNA,
  VisualColumnManager
} from '../VisualColumnManager';
import { ProjectableTerm, selectionItemFromProjectableTerm } from './common';
import {
  expandingFunctionInCalcColumns,
  qualifiedNameFromColumnRef,
  selectionWithProvenanceFromQueryAnalysis,
  expandAliasesToCalculation
} from '../../lib/soql-helpers';
import { usingNewAnalysisEndpoint } from '../../lib/feature-flag-helpers';

function newSelectionExpressionFromColumnRef(ref: TypedSoQLColumnRef): NamedExpr;
function newSelectionExpressionFromColumnRef(ref: ColumnRef): UnAnalyzedSelectedExpression;
function newSelectionExpressionFromColumnRef(
  ref: ColumnRef | TypedSoQLColumnRef
): UnAnalyzedSelectedExpression | NamedExpr {
  const name = ref.qualifier ? { name: qualifiedNameFromColumnRef(ref), position: NoPosition } : null;
  return { expr: ref, name };
}

function toggleColumnInclusion(this: VisualColumnManager, projectableTerm: ProjectableTerm) {
  if (usingNewAnalysisEndpoint()) {
    this.toggleColumnInclusionUsingNewAnalyzer(projectableTerm);
  } else {
    this.toggleColumnInclusionUsingOldAnalyzer(projectableTerm);
  }
}

function toggleColumnInclusionUsingNewAnalyzer(this: VisualColumnManager, projectableTerm: ProjectableTerm) {
  const ast = getRightmostLeafFromAnalysis(this.props.query).get;

  if (projectableTerm.expr.isDefined && projectableTerm.projectionIndex > -1) {
    const { projectionIndex } = projectableTerm;
    const selectionItem = selectionItemFromProjectableTerm(projectableTerm);

    const selectedExprs = _.cloneDeep(ast.selection.exprs);
    selectedExprs.splice(projectionIndex, 1);

    // Groupby and Aggregates are handled by their tab. We can't remove those columns from here anyway
    const where = expandAliasesToCalculation(selectionItem, ast.where);
    const joins = ast.joins.map((join) => {
      return { ...join, on: expandAliasesToCalculation(selectionItem, join.on) };
    });

    // Find index on analyzed, remove in unanalyzed
    const order_bys = _.cloneDeep(ast.order_bys);
    if (ast.order_bys) {
      const obIndex = ast.order_bys.findIndex((tob) =>
        isExpressionEqualIgnoringPosition(tob.expr, selectionItem.expr)
      );
      if (obIndex > -1) {
        order_bys.splice(obIndex, 1);
      }
    }

    const newAst: TypedSelect = {
      ...ast,
      selection: { ...ast.selection, exprs: selectedExprs },
      where,
      order_bys,
      joins
    };

    if (selectedExprs.length === 0) {
      this.props.stageAST(newAst, 'no_columns');
    } else {
      this.props.compileAST(newAst, false);
    }
  } else {
    // if there is a defined expr then we know that this is a calculated column that we want to add back in.
    // Otherwise we are including a ViewColumn->ColumnRef
    const newExpr = (() => {
      if (projectableTerm.expr.isDefined) {
        const selectionItem = selectionItemFromProjectableTerm(projectableTerm);
        return {
          expr: selectionItem.expr,
          name: { name: selectionItem.schemaEntry.name, position: NoPosition }
        };
      } else {
        return newSelectionExpressionFromColumnRef(projectableTerm.typedRef.get);
      }
    })();
    const exprs = [...ast.selection.exprs, newExpr];

    const newAst: TypedSelect = { ...ast, selection: { ...ast.selection, exprs } };
    this.props.compileAST(newAst, false);
  }
}
function toggleColumnInclusionUsingOldAnalyzer(this: VisualColumnManager, projectableTerm: ProjectableTerm) {
  const unanalyzed = getLastUnAnalyzedAst(this.props.query).get;

  if (projectableTerm.expr.isDefined && projectableTerm.projectionIndex > -1) {
    const analyzed = getLastAnalyzedAst(this.props.query).get;
    const { projectionIndex } = projectableTerm;

    // You can only exclude something already in AnalyzedAst['selection'].
    const analyzedExpr = projectableTerm.expr.get;
    // if there is a defined expr then there will be an unAnalyzedExpr
    const unAnalyzedExpr = projectableTerm.unAnalyzedExpr.get;
    const selectedExprs = _.cloneDeep(unanalyzed.selection.exprs);
    selectedExprs.splice(projectionIndex, 1);

    // Groupby and Aggregates are handled by their tab. We can't remove those columns from here anyway
    const where = expandingFunctionInCalcColumns(
      { typed: analyzedExpr, untyped: unAnalyzedExpr },
      unanalyzed.where
    );
    const joins = unanalyzed.joins.map((join) => {
      return {
        ...join,
        on: expandingFunctionInCalcColumns({ typed: analyzedExpr, untyped: unAnalyzedExpr }, join.on)
      };
    });

    // Find index on analyzed, remove in unanalyzed
    const order_bys = _.cloneDeep(unanalyzed.order_bys);
    if (analyzed.order_bys) {
      const obIndex = analyzed.order_bys.findIndex((tob) =>
        isExpressionEqualIgnoringPosition(tob.expr, analyzedExpr.expr)
      );
      if (obIndex > -1) {
        order_bys.splice(obIndex, 1);
      }
    }

    const newAst: UnAnalyzedAst = {
      ...unanalyzed,
      selection: { ...unanalyzed.selection, exprs: selectedExprs },
      where,
      order_bys,
      joins
    };

    if (selectedExprs.length === 0) {
      this.props.stageAST(newAst, 'no_columns');
    } else {
      this.props.compileAST(newAst, false);
    }
  } else {
    // if there is a defined expr then we know that this is a calculated column that we want to add back in.
    // Otherwise we are including a ViewColumn->ColumnRef
    const newExpr = projectableTerm.expr.isDefined
      ? projectableTerm.unAnalyzedExpr.get
      : newSelectionExpressionFromColumnRef(projectableTerm.ref.get);
    const exprs = [...unanalyzed.selection.exprs, newExpr];

    const ast: UnAnalyzedAst = { ...unanalyzed, selection: { ...unanalyzed.selection, exprs } };
    this.props.compileAST(ast, false);
  }
}

function toggleAllColumns(this: VisualColumnManager) {
  if (usingNewAnalysisEndpoint()) {
    this.toggleAllColumnsUsingNewAnalyzer();
  } else {
    this.toggleAllColumnsUsingOldAnalyzer();
  }
}
function toggleAllColumnsUsingNewAnalyzer(this: VisualColumnManager) {
  const ast = getRightmostLeafFromAnalysis(this.props.query).get;

  // Clicking the ThreeStateCheckbox should result in *including* all columns UNLESS we're already including
  // all columns.
  if (this.includingAllColumns(this.getColumns()) === true) {
    // then: exclude all columns
    const exprs = [] as TypedSelect['selection']['exprs'];
    // reset all sortedData based on calculated columns to their underlying expressions
    const calcColumns = analysisSuccess(this.props.query.analysisResult)
      .map((analysis) => {
        return selectionWithProvenanceFromQueryAnalysis(analysis).filter(
          (selectionItem) => !isColumnRef(selectionItem.expr)
        );
      })
      .getOrElseValue([]);

    // this looks through each calculated column, checks and modifies the sortdata as required.
    let { joins, where } = ast;
    calcColumns.forEach((col) => {
      where = expandAliasesToCalculation(col, where);
      joins = ast.joins.map((join) => {
        return { ...join, on: expandAliasesToCalculation(col, join.on) };
      });
    });

    const newAst: TypedSelect = {
      ...ast,
      selection: { ...ast.selection, exprs },
      order_bys: [],
      where: where,
      joins
    };
    this.props.stageAST(newAst, 'no_columns');
  } else {
    // then: include all columns
    const exprs = _.clone(ast.selection.exprs);
    const projection = analysisSuccess(this.props.query.analysisResult)
      .map((success) => {
        const columnsInProjection = success.outputSchema;
        return columnsInProjection.map((cip) => getSourceColumnFromSelectionNA(cip, success));
      })
      .getOrElseValue([]);

    this.getViewColumnColumnRefs()
      .filter((columnRef) => {
        return FeatureFlags.value('hide_computed_region_columns_in_ec')
          ? !columnRef.typedRef.value.startsWith(':@')
          : true;
      })
      .forEach((viewColumnRef) => {
        if (
          projection.findIndex((col) =>
            col.match({
              some: (c) => c.id == viewColumnRef.column.id,
              none: () => false
            })
          ) === -1
        ) {
          const { typedRef } = viewColumnRef;
          if (isTypedColumnRef(typedRef)) {
            exprs.push(newSelectionExpressionFromColumnRef(typedRef));
          }
        }
      });

    // add the excluded Calculated Columns
    const excludedColumns = getAllExcludedCalculatedColumnsNA(this.props.query);
    excludedColumns.forEach((excludedColumn) => {
      const { analyzedCol } = excludedColumn;
      exprs.push({ name: { name: analyzedCol.name, position: NoPosition }, expr: analyzedCol.expr });
    });

    const newAst: TypedSelect = { ...ast, selection: { ...ast.selection, exprs } };
    this.props.compileAST(newAst, false);
  }
}
function toggleAllColumnsUsingOldAnalyzer(this: VisualColumnManager) {
  const unanalyzed = getLastUnAnalyzedAst(this.props.query).get;

  // Clicking the ThreeStateCheckbox should result in *including* all columns UNLESS we're already including
  // all columns.
  if (this.includingAllColumns(this.getColumns()) === true) {
    // then: exclude all columns
    const exprs = [] as UnAnalyzedAst['selection']['exprs'];
    // reset all sortedData based on calculated columns to thier underlying expressions
    const calcColumns = getEditableSelectedExpressions(this.props.query)
      .map((some) => {
        return some.left.filter((expr) => !isColumnRef(expr.typed.expr));
      })
      .getOrElseValue([]);

    // this looks through each calculated column, checks and modifies the sortdata as required.
    let where = unanalyzed.where;
    let joins = unanalyzed.joins;
    calcColumns.forEach((col) => {
      where = expandingFunctionInCalcColumns(col, where);
      joins = unanalyzed.joins.map((join) => {
        return { ...join, on: expandingFunctionInCalcColumns(col, join.on) };
      });
    });

    const newAst: UnAnalyzedAst = {
      ...unanalyzed,
      selection: { ...unanalyzed.selection, exprs },
      order_bys: [],
      where: where,
      joins
    };
    this.props.stageAST(newAst, 'no_columns');
  } else {
    // then: include all columns
    const exprs = _.clone(unanalyzed.selection.exprs);
    const projection = compilationSuccess(this.props.query.compilationResult)
      .map((success) => {
        const columnsInProjection = lastInChain(success.analyzed).selection;
        return columnsInProjection.map((cip) => getSourceColumnFromSelection(cip, success));
      })
      .getOrElseValue([]);

    this.getViewColumnColumnRefs()
      .filter((columnRef) => {
        return FeatureFlags.value('hide_computed_region_columns_in_ec')
          ? !columnRef.typedRef.value.startsWith(':@')
          : true;
      })
      .forEach((viewColumnRef) => {
        if (
          projection.findIndex((col) =>
            col.match({
              some: (c) => c.id == viewColumnRef.column.id,
              none: () => false
            })
          ) === -1
        ) {
          const { ref } = viewColumnRef;
          exprs.push(newSelectionExpressionFromColumnRef(ref));
        }
      });

    // add the excluded Calculated Columns
    const excludedColumns = getAllExcludedCalculatedColumns(this.props.query);
    excludedColumns.forEach((excludedColumn) => {
      exprs.push(excludedColumn.unAnalyzedCol);
    });

    const newAst: UnAnalyzedAst = { ...unanalyzed, selection: { ...unanalyzed.selection, exprs } };
    this.props.compileAST(newAst, false);
  }
}

const ToggleHandlers = {
  toggleColumnInclusion,
  toggleColumnInclusionUsingOldAnalyzer,
  toggleColumnInclusionUsingNewAnalyzer,
  toggleAllColumns,
  toggleAllColumnsUsingOldAnalyzer,
  toggleAllColumnsUsingNewAnalyzer
};
export { ToggleHandlers };
