import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { option, none, Option } from 'ts-option';

import {
  ForgeToolbar,
  ForgeIconButton,
  ForgeIcon,
  ForgeSplitView,
  ForgeSplitViewPanel,
  ForgeTooltip
} from '@tylertech/forge-react';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import CompilerResult from 'common/components/CompilerResult';
import { ColumnLike, defaultMatcher, Match, Matcher } from 'common/components/SoQLDocs';
import SoQLEditor, { Editor } from 'common/components/SoQLEditor';
import { QueryCompilationResult, QueryCompilationSucceeded } from 'common/types/compiler';
import { Scope } from 'common/types/soql';

import { getColumns, getColumnsNA, compilationSuccess } from '../lib/selectors';
import { hasUnsaveableComponents } from '../lib/soql-helpers';
import { AppState, Query } from '../redux/store';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import '../styles/grid-text-editor.scss';
import SoQLQueryDocs, { renderColumn, renderFunction } from './SoQLQueryDocs';
import { buildSuccessOption, TransitionSuccessType, whichAnalyzer } from '../lib/feature-flag-helpers';

const t = (k: string, options = {}) =>
  I18n.t(k, { scope: 'shared.explore_grid.grid_soql_editor', ...options });

interface StateProps {
  fourfour: string;
  query: Query;
  scope: Scope;
  remoteStatus: Option<RemoteStatusInfo>;
  modalTargetWindow: Window | null | undefined;
  isParentHidden?: boolean;
}

interface DispatchProps {
  compile: (q: string) => void;
  runQuery: (ff: string, r: TransitionSuccessType) => void;
  onEditorLoad: (editor: Editor) => void;
}

interface ExternalProps {
  needsResize?: boolean;
  onResizeComplete?: () => void;
  resizedHeight: Option<string>;
  isParentHidden?: boolean;
  isSoqlDocsOpen: boolean;
  closeSoqlDocs: () => void;
}

type Props = StateProps & DispatchProps & ExternalProps;
interface State {
  selectedFunction: Option<Match>;
  needsResize: boolean;
}

function toColumnLikes(query: Query): ColumnLike[] {
  // @ts-expect-error TS(2322) FIXME: Type '{ fieldName: string; displayName: string | u... Remove this comment to see the full error message
  return whichAnalyzer(
    getColumns,
    getColumnsNA
  )(query).get.map((vc) => ({
    fieldName: vc.column.fieldName,
    displayName: vc.column.name,
    soqlType: vc.column.dataTypeName
  }));
}

function columnLikesHaveChanged(query: Query, nextQuery: Query): boolean {
  if (query === nextQuery) return false;
  return !_.isEqual(toColumnLikes(query), toColumnLikes(nextQuery));
}

class GridSoQLEditor extends React.Component<Props, State> {
  popup: any;
  matcher: Matcher;

  state: State = {
    selectedFunction: none,
    needsResize: false
  };

  constructor(props: Props) {
    super(props);
    this.matcher = defaultMatcher(
      props.scope,
      toColumnLikes(props.query),
      renderFunction,
      renderColumn,
      true
    );
  }

  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (
      this.props.scope.length !== nextProps.scope.length ||
      columnLikesHaveChanged(this.props.query, nextProps.query)
    ) {
      this.matcher = defaultMatcher(
        nextProps.scope,
        toColumnLikes(nextProps.query),
        renderFunction,
        renderColumn,
        true
      );
    }
  };

  // Explicitly inform the GridSoQLEditor that we want a resize when the dock-state changes.
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.modalTargetWindow?.name !== this.props.modalTargetWindow?.name) {
      this.setState({ needsResize: true });
    }
  };

  onEditorChange = (newCode: string) => {
    if (this.props.isParentHidden) {
      // No-op if the tab that launched the editor is not visible. This prevents the editor from
      // registering and compiling keystrokes that are not rendered by the ace editor
      return;
    }
    this.props.compile(newCode);
  };

  onChangeSelectedFunction = (selectedFunction: Option<Match>) => {
    this.setState({ selectedFunction });
  };

  unsaveableWarning = (cr: Option<QueryCompilationResult>) => {
    if (hasUnsaveableComponents(cr)) {
      const disallowed = 'offset and select *';
      return (
        <div className="unsaveable-warning">
          <SocrataIcon name={IconName.Warning} />
          {t('no_star_offset_search', { disallowed: disallowed })}
        </div>
      );
    }
  };

  render() {
    const busy = SelectRemoteStatus.inProgress(this.props.remoteStatus).isDefined;

    const soqlDocumentation = (
      <div className="soql-docs-split-view">
        <ForgeToolbar no-border className="datasource-header">
          <span slot="start" className="forge-typography--subtitle1-secondary">
            {t('library')}
          </span>
          <span slot="end">
            <ForgeIconButton className="grid-datasource-soql-docs-close">
              <button
                type="button"
                slot="trailing"
                aria-label={t('close_soql_docs')}
                onClick={this.props.closeSoqlDocs}
                className="tyler-icons"
              >
                <ForgeIcon name="hide_panel_right" external external-type="custom" />
                <ForgeTooltip id="grid-datasource-soql-docs-close-tooltip" position={'left'}>
                  {t('close_soql_docs')}
                </ForgeTooltip>
              </button>
            </ForgeIconButton>
          </span>
        </ForgeToolbar>
        <SoQLQueryDocs />
      </div>
    );

    const body = this.props.query.text
      .map<JSX.Element | null>((text) => (
        <SoQLEditor
          key="quiet-eslint"
          scope={this.props.scope}
          isDisabled={this.props.isParentHidden}
          compilationResult={this.props.query.compilationResult}
          soql={text}
          matcher={this.matcher}
          selectedFunction={this.state.selectedFunction}
          onChange={this.onEditorChange}
          onChangeSelectedFunction={this.onChangeSelectedFunction}
          height={this.props.resizedHeight.getOrElseValue('100%')}
          soqlMode={true}
          needsResize={this.state.needsResize}
          onResizeComplete={() => this.setState({ needsResize: false })}
          onLoad={this.props.onEditorLoad}
        />
      ))
      .getOrElseValue(null);

    return (
      <ForgeSplitView orientation="horizontal">
        <ForgeSplitViewPanel>
          <div className="grid-datasource-components scroll-container grid-text-editor forge-split-view-panel">
            <CompilerResult
              busy={busy}
              result={this.props.query.compilationResult}
              onRun={(s) => {
                // This is kinda cheating, but I don't want to change CompilerResult yet.
                buildSuccessOption(this.props.query).forEach((r) =>
                  this.props.runQuery(this.props.fourfour, r)
                );
              }}
            />
            {this.unsaveableWarning(this.props.query.compilationResult)}
            {this.props.isParentHidden && (
              <div className="unsaveable-warning">
                <SocrataIcon name={IconName.Warning} />
                {t('tab_lost_focus_warning')}
              </div>
            )}
            {body}
          </div>
        </ForgeSplitViewPanel>
        <ForgeSplitViewPanel size={450} min={325} max={'80%'} open={this.props.isSoqlDocsOpen}>
          {soqlDocumentation}
        </ForgeSplitViewPanel>
      </ForgeSplitView>
    );
  }
}

const mapStateToProps = (state: AppState, props: ExternalProps): StateProps => {
  return {
    query: state.query,
    fourfour: state.fourfourToQuery,
    scope: state.scope.getOrElseValue([]),
    remoteStatus: state.remoteStatusInfo,
    modalTargetWindow: state.modalTargetWindow,
    isParentHidden: props.isParentHidden
  };
};
const mapDispatchToProps = (dispatch: Dispatcher): DispatchProps => {
  const recompile = _.debounce((q: string) => {
    dispatch(Actions.compileText(q));
  }, 500);

  return {
    compile: (q) => {
      dispatch(Actions.setQueryText(q));
      recompile(q);
    },
    runQuery: (fourfour: string, compiled: TransitionSuccessType) => {
      dispatch(Actions.collocateAndRunQuery(fourfour, compiled));
    },
    onEditorLoad: (editor: Editor) => {
      dispatch(Actions.soqlEditorLoaded(editor));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridSoQLEditor);
