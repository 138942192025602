import React, { FunctionComponent } from 'react';
import { ForgeTextField, ForgeDatePicker, ForgeCheckbox, ForgeSelect, ForgeOption } from '@tylertech/forge-react';
import { SuggestedValuesType, SpecificValue, formatParameterDate } from 'common/types/clientContextVariable';
import { SoQLType } from 'common/types/soql';
import { dateToString } from '../lib/soql-helpers';

type ParameterInputProps = {
  label?: string;
  id: string; // also used for data test id
  dataType: SoQLType;
  value: string;
  readOnly: boolean;
  onChange: (newValue: string) => void;
  suggestedValuesType: SuggestedValuesType;
  suggestedValues?: SpecificValue[];
  validationError?: string;
  additionalInputClasses?: string; // allows you to pass extra classes to the input
};

export const ParameterInput: FunctionComponent<ParameterInputProps> = ({
  label,
  id,
  dataType,
  value,
  readOnly,
  onChange,
  suggestedValuesType,
  suggestedValues,
  validationError,
  additionalInputClasses
}: ParameterInputProps) => {

  const getAnyValueInput = () => {
    switch (dataType) {
      case SoQLType.SoQLTextT:
      case SoQLType.SoQLNumberT: {
        return (
          <ForgeTextField invalid={!!validationError}>
            <input
              type="text"
              id={id}
              data-testid={id}
              value={value}
              disabled={readOnly}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              {...(additionalInputClasses && { className: additionalInputClasses })}
            />
            <label data-testid={`${id}-label`} htmlFor={id} slot="label">
              {label}
            </label>
            {validationError ? (
              <span slot="helper-text">{validationError}</span>
            ) : null}
          </ForgeTextField>
        );
      }
      case SoQLType.SoQLFloatingTimestampT: {
        return (
          <ForgeDatePicker
            data-testid={`${id}-date-picker`}
            allowInvalidDate={true}
            disabled={readOnly}
            on-forge-date-picker-change={(e: CustomEvent) => {
              onChange(dateToString(e.detail, SoQLType.SoQLFloatingTimestampT).getOrElseValue(''));
            }}
          >
            <ForgeTextField invalid={!!validationError}>
              <input id={id} data-testid={id} defaultValue={value} type="text" readOnly={readOnly} {...(additionalInputClasses && { className: additionalInputClasses })} />
              <label htmlFor={id} data-testid={`${id}-label`}>{label}</label>
              {validationError ? (
                <span slot="helper-text">{validationError}</span>
              ) : null}
            </ForgeTextField>
          </ForgeDatePicker>
        );
      }
      case SoQLType.SoQLBooleanT:
      case SoQLType.SoQLBooleanAltT: {
        return (
          <ForgeCheckbox dense>
            <input
              type="checkbox"
              id={id}
              data-testid={id}
              disabled={readOnly}
              defaultChecked={value === 'true' ? true : false}
              onChange={(e: any) => {
                onChange(e.target.checked.toString());
              }}
              {...(additionalInputClasses && { className: additionalInputClasses })}
            />
            <label htmlFor={id} data-testid={`${id}-label`}>{label}</label>
            {validationError ? (
              <span slot="helper-text">{validationError}</span>
            ) : null}
          </ForgeCheckbox>
        );
      }
      default:
        return null;
    }
  };

  const getSpecificValueInput = () => {
    const props = {
      onChange: (e: any) => {
        onChange(e.currentTarget.value);
      },
      label: label,
      value: value,
      disabled: readOnly,
      invalid: !!validationError,
      id: id,
      ...(additionalInputClasses && { className: additionalInputClasses })
    };

    const getPreviewValue = (specificValue: SpecificValue): string => {
      if (dataType === SoQLType.SoQLFloatingTimestampT && !specificValue.displayName) {
        return formatParameterDate(specificValue.value);
      } else {
        return specificValue.displayName || specificValue.value;
      }
    };

    return (
      <ForgeSelect
        {...props}
        data-testid={id}
      >
        {suggestedValues?.map((suggestedValue, index) => {
          if (suggestedValue.value) {
            return (
              <ForgeOption value={suggestedValue.value} key={index} data-testid={`parameter-input-option-${index}`}>
                {getPreviewValue(suggestedValue)}
              </ForgeOption>
            );
          }
        })}
        {validationError ? (
          <span slot="helper-text">{validationError}</span>
        ) : null}
      </ForgeSelect>
    );
  };

  // it would be nice if the types understood that suggestedValues is always defined if the SuggestedValuesType is SPECIFIC_VALUES
  if (suggestedValuesType === SuggestedValuesType.SPECIFIC_VALUES && suggestedValues !== undefined) {
    return getSpecificValueInput();
  } else {
    return getAnyValueInput();
  }
};
