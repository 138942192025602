import SearchablePicklist from 'common/components/FilterBar/SearchablePicklist';
import { DropdownOption } from 'common/components/Forms/Dropdown';
import { FunSpec, Scope } from 'common/types/soql';
import { isFunctionDisplayable, translateFunction } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import I18n from 'common/i18n';
import { none, Option, some } from 'ts-option';
import FunctionBadge from '../visualNodes/FunctionBadge';
import { Key } from 'common/types/keyboard/key';
import { getScrollTop } from '../../lib/scroll-helpers';

const t = (k: string) => I18n.t(k, { scope: 'shared.explore_grid.change_function_picker' });

const renderFunctionBadge = (fun: Option<FunSpec>, defaultContent: string, functionBadgeTestId?: string) => {
  const props = { fun: fun.isDefined ? fun.get.name : null, defaultContent, testId: functionBadgeTestId };
  return <FunctionBadge {...props} />;
};

export interface State {
  filter: Option<string>;
  showPicker: boolean;
}

export interface Props {
  scope: Scope;
  onSelectFunction: (fc: FunSpec) => void;
  selected: Option<FunSpec>;
  prompt: string;
  // this allows you to override the default function name
  formatFunctionName?: (functionName: string, translatedName: string) => string;
  functionBadgeTestId?: string;
}

export default class AggregateFunPicker extends React.Component<Props, State> {
  state: State = {
    filter: none,
    showPicker: false
  };

  functionButtonParent: HTMLDivElement;

  // First the down arrow to activate the search
  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const elementToFocus = this.functionButtonParent.querySelector('.btn-default') as HTMLButtonElement || this.functionButtonParent;
    switch (event.key) {
      case Key.ArrowDown:
        this.showPicker();
        break;
      case Key.ArrowUp:
      case Key.Escape:
        elementToFocus.focus();
        break;
      case Key.Enter:
        event.preventDefault();
        this.showPicker();
        break;
      default:
        break;
    }
  };

  onArrowNavigationFromChild = () => {
    const element = this.functionButtonParent.querySelector('.btn-default') as HTMLButtonElement || this.functionButtonParent;
    element.focus();
  };

  onFilterFunctions = (value: string) => {
    if (value === '') {
      this.setState({ filter: none });
    } else {
      this.setState({ filter: some(value) });
    }
  };

  togglePicker = () => {
    this.setState({ showPicker: !this.state.showPicker });
  };

  showPicker = () => {
    this.setState({ showPicker: true });
  };

  hidePicker = () => {
    this.setState({ showPicker: false });
  };

  matchesFilter = (fs: FunSpec): boolean => {
    return this.state.filter.match({
      none: () => true,
      some: (filter) => {
        const normalized = filter.toLowerCase().trim();
        return (
          _.includes(fs.name.toLowerCase(), normalized) ||
          _.includes(translateFunction(fs).toLowerCase(), normalized)
        );
      }
    });
  };

  render() {
    const { scope, onSelectFunction, prompt, formatFunctionName, functionBadgeTestId } = this.props;

    const filtered = _.chain(scope)
      .filter(fs => (
        isFunctionDisplayable(fs) &&
        this.matchesFilter(fs)
      )).value();

    const options: DropdownOption<FunSpec>[] = filtered
      .sort((a, b) => translateFunction(a) > translateFunction(b) ? 1 : -1)
      .map((fs) => {
        const render = () => (
          <div className="column-header-dropdown-item">
            {formatFunctionName ? formatFunctionName(fs.name, translateFunction(fs)) : translateFunction(fs)}
          </div>
        );

        return {
          render,
          group: t('complex'),
          value: fs
        };
      });

    const selected = this.props.selected;

    return (
      <div onKeyDown={this.onKeyDown}
        ref={(div: HTMLDivElement) => { this.functionButtonParent = div; }}>
        <div className="toggle-function-picker" onClick={this.togglePicker}>
          {renderFunctionBadge(selected, prompt, functionBadgeTestId)}
        </div>
        {
          this.state.showPicker ?
            <div className="function-picker" onBlur={this.hidePicker}>
              <SearchablePicklist
                options={options}
                canAddSearchTerm={() => Promise.resolve(true)}
                hideExactMatchPrompt={true}
                hideSearchInput={false}
                size={'large'}
                value={this.state.filter.getOrElseValue('')}
                onBlur={this.hidePicker}
                onChangeSearchTerm={this.onFilterFunctions}
                onClickSelectedOption={_.noop}
                onSelection={(op: DropdownOption<FunSpec>) => {
                  onSelectFunction(op.value);
                  this.hidePicker();
                }}
                onArrowNavigationFromChild={this.onArrowNavigationFromChild}
                shouldReposition={true}
                getRepositionScrollOffset={getScrollTop}
              />
            </div> :
            null
        }
      </div>
    );
  }
}
